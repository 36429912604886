<template>
  <div
    class="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8 mt-8 mb-10"
  >
    <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
      <div class="mx-auto max-w-screen-2xl">
        <div class="mx-auto max-w-7xl sm:px-6 lg:px-8 aos-init aos-animate">
          <div class="text-center">
            <h2 class="text-4xl font-bold tracking-tight text-white">
              {{ content?.headline }}
            </h2>
            <p class="mt-2 text-lg leading-8 text-white">
              {{ content?.description }}
            </p>
          </div>

          <div id="questions">
            <div
              id="1"
              class="mt-16 flex flex-col gap-16 sm:gap-y-20 lg:flex-row"
              data-aos="zoom-in"
            >
              <div class="">
                <div class="my-5 mx-5">
                  <h3 class="text-md font-bold">
                    Haben Sie aktuell eine Webseite?
                  </h3>
                </div>
                <div
                  class="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-2"
                >
                  <div
                    class="bg-white/5 rounded-md hover:bg-stone-800 hover:scale-105 ease-in transition duration-200"
                  >
                    <div
                      class="py-4 px-8 bg-green-500 rounded-md cursor-pointer hover:bg-green-600 transition duration-300 flex justify-center items-center"
                      @click="handleSelection('Ja')"
                    >
                      <div
                        class="relative w-full h-full flex flex-col items-center justify-center"
                      >
                        <span class="text-white font-bold">👍</span>
                        <span class="text-white font-bold text-md">Ja</span>
                      </div>
                    </div>
                  </div>
                  <div
                    class="bg-white/5 rounded-md hover:bg-stone-800 hover:scale-105 ease-in transition duration-200"
                  >
                    <div
                      class="py-4 px-8 bg-green-500 rounded-md cursor-pointer hover:bg-green-600 transition duration-300 flex justify-center items-center"
                      @click="handleSelection('Nein')"
                    >
                      <div
                        class="relative w-full h-full flex flex-col items-center justify-center"
                      >
                        <span class="text-white font-bold">👎</span>
                        <span class="text-white font-bold text-md">Nein</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              id="2"
              class="mt-16 flex flex-col gap-16 sm:gap-y-20 hidden"
              data-aos="zoom-in"
            >
              <div class="">
                <div class="my-5 mx-5">
                  <h3 class="text-md font-bold">
                    Wie lautet Ihre aktuelle Domain?
                  </h3>
                  <p class="text-xs">bsp. www.kedian.de (optional)</p>
                </div>
                <div class="grid grid-cols-1 gap-8">
                  <div
                    class="flex rounded-md shadow-sm ring-1 ring-inset ring-white-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-primary-600"
                  >
                    <span
                      class="flex select-none items-center pl-3 text-gray-500 sm:text-sm"
                      >https://</span
                    >
                    <input
                      id="domain"
                      v-model="contactData.domain"
                      type="text"
                      name="domain"
                      class="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-white placeholder:text-white focus:ring-0 sm:text-sm sm:leading-6"
                      placeholder="www.ihre-webseite.de"
                    />
                  </div>
                  <div
                    class="py-2 px-4 bg-green-500 rounded-md cursor-pointer hover:bg-green-600 transition duration-300 flex justify-center items-center"
                    @click="handleSelection(contactData.domain)"
                  >
                    <div
                      class="relative w-full h-full flex flex-col items-center justify-center"
                    >
                      <span class="text-white font-bold text-md">Weiter</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              id="3"
              class="mt-16 flex flex-col gap-16 sm:gap-y-20 lg:flex-row hidden"
              data-aos="zoom-in"
            >
              <div class="">
                <div class="my-5 mx-5">
                  <h3 class="text-md font-bold">
                    Für was interessieren Sie sich?
                  </h3>
                </div>
                <div
                  class="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-2"
                >
                  <div
                    class="bg-white/5 rounded-md hover:bg-stone-800 hover:scale-105 ease-in transition duration-200"
                  >
                    <div
                      class="py-4 px-8 bg-green-500 rounded-md cursor-pointer hover:bg-green-600 transition duration-300 flex justify-center items-center"
                      @click="handleSelection('App')"
                    >
                      <div
                        class="relative w-full h-full flex flex-col items-center justify-center"
                      >
                        <span class="text-white font-bold">📱</span>
                        <span class="text-white font-bold text-md">App</span>
                      </div>
                    </div>
                  </div>
                  <div
                    class="bg-white/5 rounded-md hover:bg-stone-800 hover:scale-105 ease-in transition duration-200"
                  >
                    <div
                      class="py-4 px-8 bg-green-500 rounded-md cursor-pointer hover:bg-green-600 transition duration-300 flex justify-center items-center"
                      @click="handleSelection('Webseite')"
                    >
                      <div
                        class="relative w-full h-full flex flex-col items-center justify-center"
                      >
                        <span class="text-white font-bold">🖥</span>
                        <span class="text-white font-bold text-md"
                          >Webseite</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              id="4"
              class="mt-16 flex flex-col gap-16 sm:gap-y-20 hidden"
              data-aos="zoom-in"
            >
              <div class="">
                <div class="my-5 mx-5">
                  <h3 class="text-md font-bold">Was sind Ihre Ziele?</h3>
                </div>
                <div
                  class="grid grid-cols-1 gap-8 sm:grid-cols-1 lg:grid-cols-1"
                >
                  <div
                    class="bg-white/5 rounded-md hover:bg-stone-800 hover:scale-105 ease-in transition duration-200 w-full"
                  >
                    <div
                      class="py-4 px-8 bg-green-500 rounded-md cursor-pointer hover:bg-green-600 transition duration-300 flex justify-center items-center"
                      @click="handleSelection('Online gefunden werden')"
                    >
                      <div
                        class="relative w-full h-full flex flex-col items-center justify-center"
                      >
                        <span class="text-white font-bold text-md"
                          >🔍 Online gefunden werden</span
                        >
                      </div>
                    </div>
                  </div>

                  <div
                    class="bg-white/5 rounded-md hover:bg-stone-800 hover:scale-105 ease-in transition duration-200 w-full"
                  >
                    <div
                      class="py-4 px-8 bg-green-500 rounded-md cursor-pointer hover:bg-green-600 transition duration-300 flex justify-center items-center"
                      @click="handleSelection('Mehr Neukunden')"
                    >
                      <div
                        class="relative w-full h-full flex flex-col items-center justify-center"
                      >
                        <span class="text-white font-bold text-md"
                          >📈 Mehr Neukunden</span
                        >
                      </div>
                    </div>
                  </div>

                  <div
                    class="bg-white/5 rounded-md hover:bg-stone-800 hover:scale-105 ease-in transition duration-200 w-full"
                  >
                    <div
                      class="py-4 px-8 bg-green-500 rounded-md cursor-pointer hover:bg-green-600 transition duration-300 flex justify-center items-center"
                      @click="handleSelection('Mehr Mitarbeiter')"
                    >
                      <div
                        class="relative w-full h-full flex flex-col items-center justify-center"
                      >
                        <span class="text-white font-bold text-md"
                          >📝 Mehr Mitarbeiter</span
                        >
                      </div>
                    </div>
                  </div>

                  <div
                    class="bg-white/5 rounded-md hover:bg-stone-800 hover:scale-105 ease-in transition duration-200 w-full"
                  >
                    <div
                      class="py-4 px-8 bg-green-500 rounded-md cursor-pointer hover:bg-green-600 transition duration-300 flex justify-center items-center"
                      @click="handleSelection('Professioneller Auftritt')"
                    >
                      <div
                        class="relative w-full h-full flex flex-col items-center justify-center"
                      >
                        <span class="text-white font-bold text-md"
                          >💡 Professioneller Auftritt</span
                        >
                      </div>
                    </div>
                  </div>

                  <div
                    class="bg-white/5 rounded-md hover:bg-stone-800 hover:scale-105 ease-in transition duration-200 w-full"
                  >
                    <div
                      class="py-4 px-8 bg-green-500 rounded-md cursor-pointer hover:bg-green-600 transition duration-300 flex justify-center items-center"
                      @click="handleSelection('Alles zutreffend')"
                    >
                      <div
                        class="relative w-full h-full flex flex-col items-center justify-center"
                      >
                        <span class="text-white font-bold text-md"
                          >📍 Alles zutreffend</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              id="5"
              class="mt-16 flex flex-col gap-16 sm:gap-y-20 hidden"
              data-aos="zoom-in"
            >
              <div class="">
                <div class="my-5 mx-5">
                  <h3 class="text-md font-bold">Wann sind Sie erreichbar?</h3>
                </div>
                <div
                  class="grid grid-cols-1 gap-8 sm:grid-cols-1 lg:grid-cols-1"
                >
                  <div
                    class="bg-white/5 rounded-md hover:bg-stone-800 hover:scale-105 ease-in transition duration-200 w-full"
                  >
                    <div
                      class="py-4 px-8 bg-green-500 rounded-md cursor-pointer hover:bg-green-600 transition duration-300 flex justify-center items-center"
                      @click="handleSelection('Morgens')"
                    >
                      <div
                        class="relative w-full h-full flex flex-col items-center justify-center"
                      >
                        <span class="text-white font-bold text-md"
                          >☀️ Morgens</span
                        >
                      </div>
                    </div>
                  </div>

                  <div
                    class="bg-white/5 rounded-md hover:bg-stone-800 hover:scale-105 ease-in transition duration-200 w-full"
                  >
                    <div
                      class="py-4 px-8 bg-green-500 rounded-md cursor-pointer hover:bg-green-600 transition duration-300 flex justify-center items-center"
                      @click="handleSelection('Mittags')"
                    >
                      <div
                        class="relative w-full h-full flex flex-col items-center justify-center"
                      >
                        <span class="text-white font-bold text-md"
                          >☁️ Mittags</span
                        >
                      </div>
                    </div>
                  </div>

                  <div
                    class="bg-white/5 rounded-md hover:bg-stone-800 hover:scale-105 ease-in transition duration-200 w-full"
                  >
                    <div
                      class="py-4 px-8 bg-green-500 rounded-md cursor-pointer hover:bg-green-600 transition duration-300 flex justify-center items-center"
                      @click="handleSelection('Abends')"
                    >
                      <div
                        class="relative w-full h-full flex flex-col items-center justify-center"
                      >
                        <span class="text-white font-bold text-md"
                          >🌑 Abends</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <form
              id="6"
              class="lg:flex-auto hidden mt-10"
              data-aos="zoom-in"
              @submit.prevent="onSubmit"
            >
              <div class="my-5 mx-5">
                <h3 class="text-md font-bold">
                  Wir haben es gleich geschafft! 🎉
                </h3>
              </div>
              <div class="grid grid-cols-1 gap-x-8 gap-y-6">
                <div>
                  <label
                    for="name"
                    class="block text-sm font-light leading-6 text-gray-300"
                    >Name</label
                  >
                  <div class="mt-2.5">
                    <input
                      id="name"
                      v-model="contactData.name"
                      type="text"
                      name="name"
                      autocomplete="name"
                      class="block w-full rounded-md border-0 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-gray-900 bg-white/5 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <div>
                  <label
                    for="tel"
                    class="block text-sm font-light leading-6 text-gray-300"
                    >Telefon <span class="text-danger-600">*</span>
                  </label>
                  <div class="mt-2.5">
                    <input
                      id="tel"
                      v-model="contactData.mobileCustomer"
                      type="text"
                      name="tel"
                      required
                      autocomplete="tel"
                      class="block w-full rounded-md border-0 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-gray-900 bg-white/5 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <div>
                  <label
                    for="email"
                    class="block text-sm font-light leading-6 text-gray-300"
                  >
                    E-Mail
                  </label>
                  <div class="mt-2.5">
                    <input
                      id="email"
                      v-model="contactData.email"
                      type="email"
                      name="email"
                      autocomplete="email"
                      class="block w-full rounded-md border-0 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-gray-900 bg-white/5 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              </div>
              <div class="text-xs font-light leading-6 text-gray-400 mt-2">
                <span class="text-danger-600 text-sm">*</span> Pflichtfelder
              </div>
              <div class="mt-8 w-1/3">
                <Button type="submit" expanded> Absenden </Button>
              </div>
              <p class="mt-4 text-xs leading-6 text-white-500">
                Ich akzeptiere die
                <a href="#" class="font-semibold text-primary-600"
                  >Datenschutzbestimmungen</a
                >
                beim Absenden des Formulars.
              </p>
            </form>

            <Alert
              v-model="concatAlert.show"
              :message="concatAlert.message"
              :type="concatAlert.type"
              :dismissable="concatAlert.dismissable"
              class="grid-cols-2 mb-4 mt-6"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
/* TODO: FRAGEN UND ANTWORTEN KOMPLETT ÜBER STRAPI SETZEN KÖNNEN, nichts HARD CODED AMK MICHI DIGGA */

import { ComponentSectionsInteractiveForm } from '~/types/gql/graphql'
import createContact from '~/gql/mutations/createContact.gql'

const props = defineProps({
  content: {
    type: Object as PropType<ComponentSectionsInteractiveForm>,
    required: true,
  },
})

let currentStep = 1

const handleSelection = (answer: any) => {
  answer = answer.replace(/\s+/g, '_')

  if (currentStep === 1) {
    contactData.haveWebPage = answer
  }
  if (currentStep === 3) {
    contactData.intresProduct = answer
  }
  if (currentStep === 4) {
    contactData.goal = answer
  }
  if (currentStep === 5) {
    contactData.reacTime = answer
  }
  if (currentStep < 6 && currentStep != null) {
    document.getElementById(currentStep).style.display = 'none' // Verstecke das aktuelle Div
    currentStep++ // Gehe zum nächsten Schritt
    document.getElementById(currentStep).style.display = 'block' // Zeige das nächste Div
  }
}

const concatAlert = reactive({
  show: false,
  message: '',
  type: '',
  dismissable: false,
})

const contactData = reactive({
  name: '',
  email: '',
  mobileCustomer: '',
  domain: '',

  haveWebPage: '',
  intresProduct: '',
  goal: '',
  reacTime: '',
})

const onSubmit = () => {
  const { mutate, onDone, onError } = useMutation(
    gql`
      ${createContact}
    `,
    {
      variables: {
        data: {
          ...contactData,
        },
      },
    }
  )

  mutate()

  onDone(() => {
    contactData.name = ''
    contactData.email = ''
    contactData.mobileCustomer = ''
    contactData.haveWebPage = ''
    contactData.intresProduct = ''
    contactData.goal = ''
    contactData.reacTime = ''

    concatAlert.show = true
    concatAlert.message =
      'Wir werden uns in Kürze bei Ihnen melden. Vielen Dank! 🎉'
    concatAlert.type = 'success'
    document.getElementById(6).style.display = 'none'
  })

  onError(() => {
    concatAlert.show = true
    concatAlert.message =
      'Es ist ein Fehler aufgetreten! Bitte versuchen Sie es später erneut. 😔'
    concatAlert.type = 'danger'
  })
}
</script>
